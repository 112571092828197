import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style/common.css'
import ElementUI from 'element-ui';
import directive from './directive' // directive

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(directive)

Vue.config.productionTip = false
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
