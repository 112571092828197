import {getPermission} from "@/utils";

/**
 * 设置操作权限标识符
 */
export default {
    inserted(el, binding, vnode) {
        /** 从binding中获取dom元素的value值 */
        const { value } = binding
        /** 存放value值 */
        const btn_permission = value;
        /** 拿到所有具备权限按钮的集合，通过传入的按钮id进行匹配 */
        const permissions = getPermission();//JSON.parse(localStorage.getItem('permission'))
        /** 利用some查询，只要有一个元素满足条件就返回true，全部不满足返回false */
        var hasPermissions = permissions.some(permission => {
            return btn_permission == permission
        })
        /** 传入的按钮id不存在集合里面就移除该节点 */
        if (!hasPermissions) {
            el.parentNode && el.parentNode.removeChild(el)
        }
    }
}
